declare var $:any;
import libs from '../pages/libraries';
import collections from "../components/collections";

function api(href:any, data:any, success:any, error:any, method:any="GET"){
    $.ajax({
        url: href,
        type: method,
        contentType: "application/json; charset=utf-8",
        dataType: "html",
        data,
        success,
        error,
    })
}

function apiHandle(){
    // saigon
    let tabs = document.querySelectorAll('.saigon-2 .tabs-item');
    tabs.forEach(function(item){ 
        item.addEventListener('click', function(){
            tabs.forEach(function(delActive){
                delActive.classList.remove('active');
            })
        });
        item.addEventListener('click', saiGonTabs);
    })

    let tabsMore = document.querySelectorAll('.saigon-2__more .more');
    tabsMore.forEach(function(item){ 
        item.addEventListener('click', saiGonTabsMore);
    })

    // news
    let newsMore = document.querySelectorAll('.news-2-more .more');
    newsMore.forEach(function(item){ 
        item.addEventListener('click', newsButtonMore);
    })

    // libraries
    collections.collectionsHandle();

    let libTabs = document.querySelectorAll('.libraries-1 .tabs-item');
    libTabs.forEach(function(item){ 
        item.addEventListener('click', function(){
            libTabs.forEach(function(delActive){
                delActive.classList.remove('active');
            })
        });
        item.addEventListener('click', librariesTabs);
        item.addEventListener('click', librariesTabsPopup);
    })

    let libTabsMore = document.querySelectorAll('.libraries-1__more .more');
    libTabsMore.forEach(function(item){ 
        item.addEventListener('click', librariesTabsMore);
        item.addEventListener('click', librariesTabsMorePopup);
    })
}

function saiGonTabs(){
    let tabItem = <HTMLElement>this;
    tabItem.classList.add('active');

    let idCate = tabItem.getAttribute('data-idCate');
    let idType = tabItem.getAttribute('data-idType');
    if (idCate && idType){
        let href = "../saigon-tab";
        let data = {
            idCate: idCate,
            idType: idType
        }
        let method = "GET";
        let success = function(data:any){
            let wrapper = document.querySelector('.saigon-2__wrapper .artile-list .row');
            if (wrapper){
                wrapper.innerHTML = data;

                let tabsMore = document.querySelectorAll('.saigon-2__more .more');
                tabsMore.forEach(function(item){ 
                    item.addEventListener('click', saiGonTabsMore);
                })
            }
        }
        let error = function(){
            console.log(`Error API: ${href}`);
        }
        api(href, data, success, error, method);
    }
    else{
        console.log('Must have data-idCate and data-idType');
    }
}

function saiGonTabsMore(){
    let activeTabs = document.querySelector('.saigon-2 .tabs-item.active');
    
    if (activeTabs){
        let idCate = activeTabs.getAttribute('data-idCate');
        let idType = activeTabs.getAttribute('data-idType');
        let more = <HTMLElement>this;
        let page = more.getAttribute('data-page');

        if (idCate && idType && page){
            let href = "../saigon-tab-more";
            let data = {
                idCate: idCate,
                idType: idType,
                page: page
            }
            let method = "GET";

            let success = function(data:any){
                let more = document.querySelector('.saigon-2__more');
                if (more){
                    more.remove();
                }
                let wrapper = document.querySelector('.saigon-2__wrapper .artile-list .row');
                if (wrapper){
                    wrapper.innerHTML += data;

                    let tabsMore = document.querySelectorAll('.saigon-2__more .more');
                    tabsMore.forEach(function(item){ 
                        item.addEventListener('click', saiGonTabsMore);
                    })
                }

            }
            let error = function(){
                console.log(`Error API: ${href}`);
            }
            api(href, data, success, error, method);
        }
    }
    else{
        console.log('Must have data-idCate and data-idType');
    }
}

function librariesTabs(){
    let tabItem = <HTMLElement>this;
    tabItem.classList.add('active');

    let idCate = tabItem.getAttribute('data-idCate');
    let idType = tabItem.getAttribute('data-idType');

    if (idCate && idType){
        let href = "../libraries-tab";
        let data = {
            idCate: idCate,
            idType: idType
        }
        let method = "GET";
        let success = function(data:any){
            let wrapper = document.querySelector('.libraries-1__wrapper .collections');
            if (wrapper){
                wrapper.innerHTML = data;
                libs.clickHandlePopup();
                collections.collectionsHandle();

                let libTabsMore = document.querySelectorAll('.libraries-1__more .more');
                libTabsMore.forEach(function(item){ 
                    item.addEventListener('click', librariesTabsMore);
                    item.addEventListener('click', librariesTabsMorePopup);
                })
            }
        }
        let error = function(){
            console.log(`Error API: ${href}`);
        }
        api(href, data, success, error, method);
    }
    else{
        console.log('Must have data-idCate and data-idType');
    }
}

function librariesTabsMore(){
    let activeTabs = document.querySelector('.libraries-1 .tabs-item.active');
    
    if (activeTabs){
        let idCate = activeTabs.getAttribute('data-idCate');
        let idType = activeTabs.getAttribute('data-idType');
        let more = <HTMLElement>this;
        let page = more.getAttribute('data-page');

        if (idCate && idType && page){
            let href = "../libraries-tab-more";
            let data = {
                idCate: idCate,
                idType: idType,
                page: page
            }
            let method = "GET";

            let success = function(data:any){
                let more = document.querySelector('.libraries-1__more');
                if (more){
                    more.remove();
                }

                let wrapper = document.querySelector('.libraries-1__wrapper .collections');
                if (wrapper){
                    wrapper.innerHTML += data;
                    libs.clickHandlePopup();
                    collections.collectionsHandle();
                    
                    let libTabsMore = document.querySelectorAll('.libraries-1__more .more');
                    libTabsMore.forEach(function(item){ 
                        item.addEventListener('click', librariesTabsMore);
                        item.addEventListener('click', librariesTabsMorePopup);
                    })
                }

                // let more = document.querySelector('.libraries-1__more .more');
                // if (more){
                //     // let page = more.getAttribute('data-page');
                //     // if (!parseInt(page)){
                //     //     console.log('data-page must be number');
                //     // }
                //     // else{
                //     //     more.setAttribute('data-page', parseInt(page) + 1 + '');
                //     // }
                // }
            }
            let error = function(){
                console.log(`Error API: ${href}`);
            }
            api(href, data, success, error, method);
        }
    }
    else{
        console.log('Must have data-idCate and data-idType');
    }
}

function librariesTabsPopup(){
    let activeTabs = document.querySelector('.libraries-1 .tabs-item.active');
    
    if (activeTabs){
        let idCate = activeTabs.getAttribute('data-idCate');
        let idType = activeTabs.getAttribute('data-idType');
        // let more = <HTMLElement>this;
        // let page = more.getAttribute('data-page');

        if (idCate && idType){
            let href = "../libraries-tab-popup";
            let data = {
                idCate: idCate,
                idType: idType
                // page: page
            }
            let method = "GET";

            let success = function(data:any){
                // let more = document.querySelector('.libraries-1__more .more');
                // if (more){
                //     more.remove();
                // }

                let wrapper = document.querySelector('.libraries-1 .popup-lib .swiper-wrapper');
                if (wrapper){
                    wrapper.innerHTML = data;

                    // let libTabsMore = document.querySelectorAll('.libraries-1__more .more');
                    // libTabsMore.forEach(function(item){ 
                    //     item.addEventListener('click', librariesTabsMore);
                    //     item.addEventListener('click', librariesTabsMorePopup);
                    // })
                }

                // let more = document.querySelector('.libraries-1__more .more');
                // if (more){
                //     let page = more.getAttribute('data-page');
                //     if (!parseInt(page)){
                //         console.log('data-page must be number');
                //     }
                //     else{
                //         more.setAttribute('data-page', parseInt(page) + 1 + '');
                //     }
                // }
            }
            let error = function(){
                console.log(`Error API: ${href}`);
            }
            api(href, data, success, error, method);
        }
    }
    else{
        console.log('Must have data-idCate and data-idType');
    }
}

function librariesTabsMorePopup(){
    let activeTabs = document.querySelector('.libraries-1 .tabs-item.active');
    
    if (activeTabs){
        let idCate = activeTabs.getAttribute('data-idCate');
        let idType = activeTabs.getAttribute('data-idType');
        let more = <HTMLElement>this;
        let page = more.getAttribute('data-page');

        if (idCate && idType && page){
            let href = "../libraries-tab-more-popup";
            let data = {
                idCate: idCate,
                idType: idType,
                page: page
            }
            let method = "GET";

            let success = function(data:any){
                // let more = document.querySelector('.libraries-1__more .more');
                // if (more){
                //     more.remove();
                // }

                let wrapper = document.querySelector('.libraries-1 .popup-lib .swiper-wrapper');
                if (wrapper){
                    wrapper.innerHTML += data;

                    // let libTabsMore = document.querySelectorAll('.libraries-1__more .more');
                    // libTabsMore.forEach(function(item){ 
                    //     item.addEventListener('click', librariesTabsMore);
                    //     item.addEventListener('click', librariesTabsMorePopup);
                    // })
                }

                // let more = document.querySelector('.libraries-1__more .more');
                // if (more){
                //     let page = more.getAttribute('data-page');
                //     if (!parseInt(page)){
                //         console.log('data-page must be number');
                //     }
                //     else{
                //         more.setAttribute('data-page', parseInt(page) + 1 + '');
                //     }
                // }
            }
            let error = function(){
                console.log(`Error API: ${href}`);
            }
            api(href, data, success, error, method);
        }
    }
    else{
        console.log('Must have data-idCate and data-idType');
    }
}

function newsButtonMore(){
    let more = <HTMLElement>this;
    let page = more.getAttribute('data-page');
    let idCate = more.getAttribute('data-idCate');

    if (idCate && page){
        let href = "../news-more";
        let data = {
            idCate: idCate,
            page: page
        }
        let method = "GET";

        let success = function(data:any){
            let more = document.querySelector('.news-2-more');
            if (more){
                more.remove();
            }

            let wrapper = document.querySelector('.news-2-wrapper');
            if (wrapper){
                wrapper.innerHTML += data;

                let newsMore = document.querySelectorAll('.news-2-more .more');
                newsMore.forEach(function(item){ 
                    item.addEventListener('click', newsButtonMore);
                })
            }
        }
        let error = function(){
            console.log(`Error API: ${href}`);
        }
        api(href, data, success, error, method);
    }
    else{
        console.log('Must have data-idCate');
    }
}

function formContact(){
    $('#form-Contact').submit(function (e:any) {
        e.preventDefault();
        var model = {
            FullName: $('#contactName').val(),
            Title: $('#contactTitle').val(),
            Email: $('#contactEmail').val(),
            Phone: $('#contactPhone').val(),
            Content: $('#contactNote').val(),
            Recaptcha: $('#Recaptcha').val(),
            Type: $('#contactType').val()
        }
        if (model.FullName != '' && model.Email != '' && model.Phone != '' && model.Content != '') {
            $.ajax({
                type: 'POST',
                url: '/submit',
                data: { model: model },
                dataType: 'json',
                success: function (res:any) {
                    if (res.Code == 200) {
                        $('#form-Contact')[0].reset();
                        $('#successMessage').removeAttr('hidden')
                    } else {
                        $('#alertMessage').removeAttr('hidden')
                    }
                }
            })
        } else {
            $('#alertMessage').removeAttr('hidden')
        }
    })
}

export const callApi = function(){
    apiHandle();

    formContact();
}