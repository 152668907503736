declare var Swiper : any;

function initSwiper(){
    if (document.querySelector('.index-6 .swiper')){
        var sw = new Swiper('.index-6 .swiper',{
            spaceBetween: 30,
            // effect: "fade",
    
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                delay: 3000,
            },
            speed: 1000,
        })
    }

    if (document.querySelector('.homepage')){
        var sw = new Swiper('.homepage-cards .swiper',{
            spaceBetween: 120,
            slidesPerView: 2,
            // effect: "fade",
            navigation: {
                nextEl: ".homepage-cards .swiper-custom-button-next",
                prevEl: ".homepage-cards .swiper-custom-button-prev",
            },
            // pagination: {
            //     el: ".swiper-pagination",
            //     clickable: true,
            // },
            autoplay: {
                delay: 4000,
            },
            speed: 1200,

            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                },
                992: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                },
                1680: {
                    slidesPerView: 2,
                    spaceBetween: 120,
                }
            }
        })
    }
}


export const index = function(){
    initSwiper();
}