declare var Swiper : any;

function initSwiper(numberActive:number = 0){
    var sw = new Swiper('.libraries-1 .popup-lib .swiper',{
        spaceBetween: 30,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        speed: 800,
        initialSlide: numberActive + '',
    })

    // sw.slideTo(numberActive);
}

function clickHandlePopup(){
    if (!document.querySelector('.libraries-1')){
        return;
    }

    var icons = document.querySelectorAll('.libraries-1 .collections-item--hover .icons');
    var popup = document.querySelector('.popup-lib');
    var closePopup = <HTMLElement>popup.querySelector('.close');
    var body = document.querySelector('body');

    icons.forEach(function(item:HTMLElement, index){
        item.onclick = function(e){
            e.preventDefault();
            popup.classList.add('show');
            body.classList.add('hiddenScroll');

            let numberActive = index;

            // let numberActive = item.getAttribute('slide');
            // if (parseInt(numberActive)){
            //     document.querySelector('.libraries-1') && initSwiper(parseInt(numberActive));
            // }
            if (numberActive !== undefined){
                document.querySelector('.libraries-1') && initSwiper(numberActive);
            }
            else{
                document.querySelector('.libraries-1') && initSwiper();
            }
        }
    })

    closePopup.onclick = function(e){
        e.preventDefault();
        popup.classList.remove('show');
        body.classList.remove('hiddenScroll');
    }
}

export const libraries = function(){
    clickHandlePopup();
}

export default {
    clickHandlePopup
}