declare var $: any;
const months = ['January','February','March','April', 'May','June','July','August', 
'September','October','November','December'];
const dayOfWeeks = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

function inputNumberHandle(){
    let inps = document.querySelectorAll('.group-inp .inp');
    inps.forEach(function(inpItem){
        let num = <HTMLInputElement>inpItem.querySelector('.input-number');
        let icons = inpItem.querySelectorAll('.inp-icons');

        if (icons && num){
            let iconUp = inpItem.querySelector('.icon-item[data-value="up"]');
            let iconDown = inpItem.querySelector('.icon-item[data-value="down"]');
            
            if (!num.getAttribute('data-min') || !num.getAttribute('data-max')){
                num.onkeypress = function(evt){
                    let currentNum = num.value;
                    if (currentNum.length >= 3){
                        return false;
                    }
        
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
                    if ((charCode < 48 || charCode > 57))
                        return false;
                    
                    return true;
                }
            }
            else{
                let minNum = parseInt(num.getAttribute('data-min'));
                let maxNum = parseInt(num.getAttribute('data-max'));
    
                num.onkeypress = function(evt){
                    let currentNum = num.value;
                    if (currentNum.length >= 3){
                        return false;
                    }
        
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
                    if ((charCode < 48 || charCode > 57))
                        return false;
        
                    let futureNum = currentNum + evt.key;
                    
                    if (parseInt(futureNum) == minNum){
                        iconDown.setAttribute('data-disable', 'true');
                    }
                    else{
                        iconDown.setAttribute('data-disable', '');
                    }
                    if (parseInt(futureNum) == maxNum){
                        iconUp.setAttribute('data-disable', 'true');
                    }
                    else{
                        iconUp.setAttribute('data-disable', '');
                    }
                    
                    return true;
                }
            }
        }
        else{
            if (num){
                num.onkeypress = function(evt){
                    let currentNum = num.value;
                    if (currentNum.length >= 3){
                        return false;
                    }
        
                    var charCode = (evt.which) ? evt.which : evt.keyCode;
                    if ((charCode < 48 || charCode > 57))
                        return false;
                    
                    return true;
                }
            }
        }
    })
}

// function addIconsNumber(item:HTMLElement){
//     console.log(1);
//     item.innerHTML += `
//     <div class="inp-icons"> 
//     <div class="icon-item" data-value="up"> 
//             <svg xmlns="http://www.w3.org/2000/svg" width="15.328" height="9.078" viewBox="0 0 15.328 9.078">
//                 <path d="M0,0,6.25,6.25,12.5,0" transform="translate(13.914 7.664) rotate(180)" fill="none" stroke="#006d58" stroke-linecap="round" stroke-width="2"/>
//                 </svg>
//         </div>
//         <div class="icon-item" data-value="down" data-disable="true">
//             <svg xmlns="http://www.w3.org/2000/svg" width="15.328" height="9.078" viewBox="0 0 15.328 9.078">
//                 <path d="M0,0,6.25,6.25,12.5,0" transform="translate(1.414 1.414)" fill="none" stroke="#006d58" stroke-linecap="round" stroke-width="2"/>
//             </svg>
//         </div>
//     </div>
//     `;
// }

function numberUpDownHandle(){
    let inps = document.querySelectorAll('.group-inp .inp');
    inps.forEach(function(item:HTMLElement){
        let inputItem = <HTMLInputElement>item.querySelector('.input-number');
        // if (!item.querySelector('.inp-icons') && inputItem) {
        //     addIconsNumber(item);
        // }

        if (inputItem){
            let icons = item.querySelectorAll('.icon-item');
            icons.forEach(function(icon){
                icon.addEventListener('click', function(e){
                    e.preventDefault();
                    if (icon.getAttribute('data-disable') == "true"){
                        return false;
                    }

                    let minValue = parseInt(inputItem.getAttribute('data-min'));
                    let maxValue = parseInt(inputItem.getAttribute('data-max'));
                    let currentValue = parseInt(inputItem.value);

                    if (!currentValue){
                        currentValue = minValue;
                    }
                    let futureValue = currentValue;

                    if (icon.getAttribute('data-value') == "up"){
                        futureValue = upHandle(currentValue, maxValue);
                    }
                    else if (icon.getAttribute('data-value') == "down"){
                        futureValue = downHandle(minValue, currentValue);
                    }

                    inputItem.value = `${futureValue}`;

                    let disableMinValue = item.querySelector('.icon-item[data-value="down"]');
                    if (futureValue == minValue){
                        disableMinValue.setAttribute('data-disable', 'true');
                    }
                    else{
                        disableMinValue.setAttribute('data-disable', '');
                    }

                    let disableMaxValue = item.querySelector('.icon-item[data-value="up"]');
                    if (futureValue == maxValue){
                        disableMaxValue.setAttribute('data-disable', 'true');
                    }
                    else{
                        disableMaxValue.setAttribute('data-disable', '');
                    }
                })
            })
        }
    })
}

function upHandle(currentValue:number, maxValue:number){
    if (currentValue < maxValue){
        return ++currentValue;
    }
    else{
        return currentValue;
    }
}
function downHandle(minValue:number, currentValue:number){
    if (currentValue > minValue){
        return --currentValue;
    }
    else{
        return currentValue;
    }
}


function initFormTable(){
    if (document.getElementById('dateInTable')){
        $('#dateInTable').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
            timepicker:false,
            format:'d/m/Y',
            minDate: new Date(), 
        });
        $.datetimepicker.setLocale('en');
    }

    if (document.getElementById('timeTable')){
        $('#timeTable').datetimepicker({
            datepicker:false,
            format:'H:i',
            // allowTimes:[
            //     '00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30',
            //     '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', 
            //     '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', 
            //     '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', 
            //     '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'
            // ]
            step: 30,
            defaultTime: new Date(),
        });
        $.datetimepicker.setLocale('en');
    }
}

function initFormBooking(){
    if (document.getElementById('dateInBooking')){
        $('#dateInBooking').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
            timepicker:false,
            format:'d/m/Y',
            minDate: new Date(),

            onChangeDateTime:function(dateChange:any){
                let outDate = <HTMLInputElement>document.getElementById('dateOutBooking');
                let newOutDate = outDate.value;

                let inDate = new Date(dateChange);
                const yyyy = inDate.getFullYear();
                let mm = inDate.getMonth() + 1; // Months start at 0!
                let dd = inDate.getDate();
                let date = '';
                let month = '';
                if (dd < 10) {
                    date = '0' + dd;
                }
                else{
                    date = dd + '';
                }
                if (mm < 10) {
                    month = '0' + mm;
                }
                else{
                    month = mm + '';
                }

                // let newInDate = `${inDate.getDate()}/${inDate.getMonth() + 1}/${inDate.getFullYear()}`;
                let newInDate = `${date}/${month}/${yyyy}`;
                if (newOutDate){
                    let compare = compareTime(newInDate, newOutDate);
                    if (compare == -1){
                        outDate.value = newInDate;
                    }
                }
                else{
                    outDate.value = newInDate;
                }
            }
        });
        $.datetimepicker.setLocale('en');
    }

    if (document.getElementById('dateOutBooking')){
        $('#dateOutBooking').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
            timepicker:false,
            format:'d/m/Y',
            minDate: new Date(), 
            onChangeDateTime:function(dateChange:any){
                // let outDate = new Date(dateChange);
                // let newOutDate = `${outDate.getDate()}/${outDate.getMonth() + 1}/${outDate.getFullYear()}`;
                
                let inDate = <HTMLInputElement>document.getElementById('dateInBooking');
                let newInDate = inDate.value;
                
                let outDate = new Date(dateChange);
                const yyyy = outDate.getFullYear();
                let mm = outDate.getMonth() + 1; // Months start at 0!
                let dd = outDate.getDate();
                let date = '';
                let month = '';
                if (dd < 10) {
                    date = '0' + dd;
                }
                else{
                    date = dd + '';
                }
                if (mm < 10) {
                    month = '0' + mm;
                }
                else{
                    month = mm + '';
                }
                let newOutDate = `${date}/${month}/${yyyy}`;

                if (newInDate){
                    let compare = compareTime(newInDate, newOutDate);
                    if (compare == -1){
                        inDate.value = newOutDate;
                    }
                }
                else{
                    inDate.value = newOutDate;
                }
            }
        });
        $.datetimepicker.setLocale('en');
    }
}

function initDateCallback(dateChange:any, idString:string){
    if (dateChange){
        let newDate = new Date(dateChange);
        let inpItem = document.getElementById(idString);
        if (!inpItem){
            return;
        }
        let parentItem = inpItem.parentElement;
        parentItem.querySelector('.day').innerHTML = dayOfWeeks[newDate.getDay()];
        parentItem.querySelector('.date').innerHTML = `${newDate.getDate()}`;
        parentItem.querySelector('.month').innerHTML = months[newDate.getMonth()];
        parentItem.querySelector('.year').innerHTML = `${newDate.getFullYear()}`;
    }
}

function compareTime(time1:string, time2:string){
    let str1 = time1.split('/');
    let str2 = time2.split('/');
    let i = 2;
    for(; i >= 0; i--){
        if (parseInt(str1[i]) == parseInt(str2[i])){
            continue;
        }
        if (parseInt(str2[i]) > parseInt(str1[i])){
            return 1;
        }
        else{
            return -1;
        }
    }
    return 0;
}

function initFormHeader(){
    if (document.getElementById('dateInHeader')){
        let checkInit = false;
        $('#dateInHeader').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
            value: new Date(),
            timepicker:false,
            format:'d/m/Y',
            minDate: new Date(), 

            onChangeDateTime:function(dateChange:any){
                // let day = dateChange.
                initDateCallback(dateChange, 'dateInHeader');
                let timeOutHeader = <HTMLInputElement>document.getElementById('dateOutHeader');
                let timeInHeader = <HTMLInputElement>document.getElementById('dateInHeader');
                let compare = compareTime(timeInHeader.value, timeOutHeader.value);
                if (compare == -1){
                    initDateCallback(dateChange, 'dateOutHeader');
                }
            },
            onGenerate: function(){
                if (checkInit){
                    return false;
                }
                initDateCallback(new Date(), 'dateInHeader');
                checkInit = true;
            }
        });
        $.datetimepicker.setLocale('en');
        // initInputDate();
    }

    if (document.getElementById('dateOutHeader')){
        let checkInit = false;
        $('#dateOutHeader').datetimepicker({
            i18n:{
                de:{
                 months:[
                  'Januar','Februar','März','April',
                  'Mai','Juni','Juli','August',
                  'September','Oktober','November','Dezember',
                 ],
                 dayOfWeek:[
                  "So.", "Mo", "Di", "Mi", 
                  "Do", "Fr", "Sa.",
                 ]
                }
            },
            value: new Date(),
            timepicker:false,
            format:'d/m/Y',
            minDate: new Date(), 

            onChangeDateTime:function(dateChange:any){
                // let day = dateChange.
                initDateCallback(dateChange, 'dateOutHeader');

                let timeOutHeader = <HTMLInputElement>document.getElementById('dateOutHeader');
                let timeInHeader = <HTMLInputElement>document.getElementById('dateInHeader');
                let compare = compareTime(timeInHeader.value, timeOutHeader.value);
                
                if (compare == -1){
                    initDateCallback(dateChange, 'dateInHeader');
                }
            },
            onGenerate: function(){
                if (checkInit){
                    return false;
                }
                initDateCallback(new Date(), 'dateOutHeader');
                checkInit = true;
            }
        });
        $.datetimepicker.setLocale('en');
    }
}

function activeFormHandle(){
    let requireBtn = document.getElementById('btnRequireFixed');
    let bookingBtn = document.getElementById('btnBookingFixed');
    let tableBtns = document.querySelectorAll('.btnTableFixed');
    let bookingBtns = document.querySelectorAll('.btnBookingFixed');

    if (requireBtn){
        btnClickHandle(requireBtn);
    }
    if (bookingBtn){
        btnClickHandle(bookingBtn);
    }

    tableBtns.forEach(function(itemBtn:HTMLElement){
        btnClickHandle(itemBtn, true);
    })
    bookingBtns.forEach(function(itemBtn:HTMLElement){
        btnClickHandle(itemBtn, true);
    })
}

function popupBookingHandle(){
    let idBookingHeader = document.getElementById('bookingHeader');
    let popupBooking = document.querySelector('.header .popup-booking');
    if (popupBooking && idBookingHeader){
        let timeoutBtn:any;
        let check = false;
        idBookingHeader.addEventListener('click', function(e){
            e.preventDefault();
            clearTimeout(timeoutBtn);
            timeoutBtn = setTimeout(function(){
                check = false;
            }, 600)

            if (check){
                return;
            }

            popupBooking.classList.toggle('show');
            check = true;
        })

        let close = popupBooking.querySelector('.close');
        if (close){
            close.addEventListener('click', function(){
                popupBooking.classList.remove('show');
            })
        }
    }
}

function btnClickHandle(btn:HTMLElement, preventDefault: boolean= false){
    if (btn){
        let formItems = document.querySelectorAll('.form-fixed__item');
        let btnFixed = document.querySelector('.buttons-fixed');

        btn.addEventListener('click', function(e){
            if (preventDefault){
                e.preventDefault();
            }
            let classFormPopup = btn.getAttribute('data-form');
            if (classFormPopup){
                formItems.forEach(function(itemForm){
                    if (itemForm.classList.contains(classFormPopup)){
                        btnFixed.classList.add('hiddenBtn');
                        setTimeout(function(){
                            if (window.innerWidth < 768){
                                document.querySelector('body').classList.add('hiddenScroll');
                            }

                            formItems.forEach(function(exp){
                                exp.classList.remove('show');
                            })
                            itemForm.classList.add('show');
                        }, 300)
                    }
                })
            }
        })

        formItems.forEach(function(item){
            let close = item.querySelector('.close');
            close.addEventListener('click', function(){
                item.classList.remove('show');
                document.querySelector('body').classList.remove('hiddenScroll');
                
                setTimeout(function(){
                    btnFixed.classList.remove('hiddenBtn');
                }, 300)
            })
        })
    }
}

function selectOptionsHandle(){
    let selects = document.querySelectorAll('.select');
    selects.forEach(function(itemSelect:HTMLElement){
        let inputHidden = <HTMLInputElement>itemSelect.querySelector('.select-hidden');
        let blockSelect = itemSelect.querySelector('.select-block');
        let blockName = itemSelect.querySelector('.select-block__name');
        let itemOptions = itemSelect.querySelectorAll('.select-options__item');
        blockSelect.addEventListener('click', function(e){
            e.preventDefault();
            blockSelect.classList.toggle('actived');
        })

        itemOptions.forEach(function(opt:HTMLElement){
            opt.addEventListener('click', function(e){
                e.preventDefault();
                itemOptions.forEach(function(reOpt){
                    reOpt.classList.remove('active');
                })
                opt.classList.add('active');
                blockName.innerHTML = opt.innerHTML;
                blockSelect.classList.remove('actived');

                if (inputHidden){
                    inputHidden.value = opt.getAttribute('data-value');
                }
            })
            
        })

        clickOutHandle(itemSelect);
    })
}

function clickOutHandle(item:HTMLElement){
    if (!item){
        return;
    }
    let blockSelect = item.querySelector('.select-block');
    document.addEventListener('click', function(e){
        let evt = <HTMLElement>e.target;
        do{
            if (evt == item){
                return;
            }
            evt = <HTMLElement>evt.parentNode;
        } while(evt)

        if (evt != item){
            blockSelect.classList.remove('actived');
        }
    })
}

export const form = function(){
    numberUpDownHandle();
    inputNumberHandle();
    selectOptionsHandle();

    popupBookingHandle();
    activeFormHandle();
    
    initFormHeader();
    initFormTable();
    initFormBooking();
}