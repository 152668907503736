function init(){
    let bcitems = document.querySelectorAll('.breadcrumbs__item');
    bcitems.forEach(function(item, index){
        if (index != 0){
            item.innerHTML += `
            <svg xmlns="http://www.w3.org/2000/svg" width="6.023" height="11.834" viewBox="0 0 6.023 11.834">
                <g id="Group_4932" data-name="Group 4932" transform="translate(-1708.23 -2419.531)">
                    <path d="M1709.143,2416.143l4.816,5.448-4.816,4.974" transform="translate(-0.206 4.093)" fill="none" stroke="#848484" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                </g>
            </svg> 
            `;
        }
    })
}

export const breadcrumbs = function(){
    init();
}