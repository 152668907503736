// https://fancyapps.com/docs/ui/fancybox
// https://www.npmjs.com/package/axios
// https://greensock.com/
// https://swiperjs.com/get-started

declare var Swiper : any;
declare var $ : any;
declare var AOS : any;
declare var gsap : any;
declare var anime : any;
declare var Parallax:any;

// AOS.init({
//   once: true
// });

// import axios from "axios";

//////////////////////////////////////////////////////////
// Common function
// import {fullpage} from "./components/fullpage";
import {scrollToTop} from "./components/scrollToTop";
import {popupImg} from "./components/popupImg";
import {callApi} from "./components/callApi";
import {gsapScroll} from "./components/gsapScroll";

// Layout
import {header} from "./layout/header";
import {footer} from "./layout/footer";
import {banner} from "./layout/banner";
import {breadcrumbs} from "./layout/breadcrumbs";
import {form} from "./layout/form";
// import {GoogleMapApiController} from "./layout/GoogleMapApiController";

// Page
import {index} from "./pages/index";
import {libraries} from "./pages/libraries";
import {restroom} from "./pages/restroom";
// import {contact} from "./pages/contact";


///////////////////////////////////////////////////////////////
// function Common
function common(){
  // fullpage();
  
  if (document.getElementById('cuisinePage')){
    document.querySelector('body').classList.add('overlayOpacity');
  }
  if (document.getElementById('contactPage')){
    document.querySelector('body').classList.add('light');
  }

  let swiperNext = document.querySelectorAll('.swiper-button-next');  
  swiperNext.forEach(function(item){
    item.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="9.078" height="15.328" viewBox="0 0 9.078 15.328">
          <path d="M349,1337.065l6.25-6.25,6.25,6.25" transform="translate(-1329.401 362.914) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
      </svg>
      `;
  })
  let swiperPrev = document.querySelectorAll('.swiper-button-prev');  
  swiperPrev.forEach(function(item){
    item.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="9.078" height="15.328" viewBox="0 0 9.078 15.328">
          <path d="M349,1337.065l6.25-6.25,6.25,6.25" transform="translate(-1329.401 362.914) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
      </svg>
      `;
  })
  let swiperNextCustom = document.querySelectorAll('.swiper-custom-button-next');  
  swiperNextCustom.forEach(function(item){
    item.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="9.078" height="15.328" viewBox="0 0 9.078 15.328">
          <path d="M349,1337.065l6.25-6.25,6.25,6.25" transform="translate(-1329.401 362.914) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
      </svg>
      `;
  })
  let swiperPrevCustom = document.querySelectorAll('.swiper-custom-button-prev');  
  swiperPrevCustom.forEach(function(item){
    item.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="9.078" height="15.328" viewBox="0 0 9.078 15.328">
          <path d="M349,1337.065l6.25-6.25,6.25,6.25" transform="translate(-1329.401 362.914) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
      </svg>
      `;
  })

  scrollToTop();
  popupImg();
  callApi();
  gsapScroll();
}

function layout(){
  header();
  footer();
  banner();
  breadcrumbs();
  form();
  // GoogleMapApiController();
}

// function Page
function page(){
  // ----- add page in here ----- //
  index();
  libraries();
  restroom();
}

function allJs(){
  common();
  page()
  layout();
}

/////////////////////WEB FULLPAGE///////////////////////////
// import loadingAni from "./initAnimation/loading";

// const fullpageLoad = function(){
//   let loading = document.querySelector('#loading-container');
//   loadingAni.aniIn();
//   // import animateFullpage from "./components/animateFullpage";
//   // animateFullpage.default();
  
//   window.addEventListener('load', function() {
//     // swiper_nav();
//     allJs();
//     setTimeout(function(){
//       loading.classList.add('hiddenLoading');
//       setTimeout(function(){
//         loading.parentElement.removeChild(loading);

//         // let utiSubMap = document.getElementById('UtilitiesSubPage');
//         // if (utiSubMap){
//         //   let map = utiSubMap.querySelector('.map');
//         //   map.classList.add('animateActive');
//         // }
//       }, 700)
//       common();
//     }, loadingAni.delay)
//   })
// }

// fullpageLoad();

/////////////////////WEB NORMAL///////////////////////////

window.addEventListener('load', function() {
  allJs();
})