declare var Swiper : any;

function initSwiper(){
    var sw = new Swiper('.banner .swiper',{
        spaceBetween: 30,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        speed: 1000,
    })
}

export const banner = function(){
    document.querySelector('.banner') && initSwiper();
}