
function collectionsHandle(){
    let collecs = document.querySelectorAll('.collections-item');
    let countCircle = 0;
    let checkCircle = true;

    collecs.forEach(function(item, index){
        if (countCircle < 2){
            let idxCircle = index + parseInt(checkCircle?"1":"0");
            if (idxCircle % 2 == 0){
                console.log(index);
                countCircle++;
                item.setAttribute('data-style', 'circle');
            }
        }
        else{
            checkCircle = !checkCircle;
            countCircle = 0;
            item.setAttribute('data-style', 'merge');
        }
    })
}

export default{
    collectionsHandle
}