
function init(){
    let stt = <HTMLElement>document.querySelector('.scroll-to-top');
    if (stt){
        stt.onclick = function(){
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            })
        }

        let footer = document.getElementById('footer');
        if (!footer){
            return;
        }

        let offsetFooter = footer.offsetTop;
        stt.style.display = "none";

        let timeoutRs:any;
        window.addEventListener('resize', function(){
            timeoutRs = this.setTimeout(function(){
                offsetFooter = footer.offsetTop;
            }, 200)
        })

        let timeoutScroll:any;
        window.addEventListener('scroll', function(e){
            timeoutScroll = this.setTimeout(function(){
                if (window.scrollY + window.innerHeight >= offsetFooter){
                    // console.log(window.scrollY + window.innerHeight);
                    stt.style.display = "";
                }
                else{
                    stt.style.display = "none";
                }
            }, 200)
        })
    }
}

export const scrollToTop = function(){
    init();
}