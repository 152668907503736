declare var Swiper : any;

function initPopupSwiper(numberActive:number = 0){
    var sw = new Swiper('.popup-lib .swiper',{
        spaceBetween: 30,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        speed: 800,
        initialSlide: numberActive + '',
    })
}

function clickHandlePopup(itemSection:HTMLElement, popupLib: HTMLElement){
    // if (!document.querySelector(strSection)){
    //     return;
    // }
    // var itemSection = document.querySelector(strSection);

    itemSection.classList.add('images-item');
    
    itemSection.innerHTML += `
    <div class="images-item--hover">
        <div class="icons">
            <svg xmlns="http://www.w3.org/2000/svg" width="31.396" height="31.396" viewBox="0 0 31.396 31.396">
                <g transform="translate(191 -793.294)">
                    <path d="M-191,821.1v-24.22c.01-.046.023-.091.03-.137a3.85,3.85,0,0,1,2.629-3.233,7.544,7.544,0,0,1,.93-.219h24.22c.041.01.082.023.123.029a3.851,3.851,0,0,1,3.237,2.6,7.346,7.346,0,0,1,.228.959V821.1a1.319,1.319,0,0,0-.03.138,3.931,3.931,0,0,1-3.026,3.351c-.176.039-.355.066-.532.1h-24.22c-.041-.01-.082-.023-.123-.029a3.856,3.856,0,0,1-3.233-2.586A7.255,7.255,0,0,1-191,821.1Zm15.715-25.85h-11.709a1.972,1.972,0,0,0-2.047,2.034q0,11.709,0,23.418a1.971,1.971,0,0,0,2.022,2.027h23.434a1.969,1.969,0,0,0,2.023-2.026q0-11.717,0-23.434a2.162,2.162,0,0,0-.047-.46,1.962,1.962,0,0,0-1.966-1.558Z" fill="#fff"/>
                    <path d="M-166.792,818.937c0,.833.005,1.665,0,2.5a.926.926,0,0,1-.523.856.928.928,0,0,1-1-.055.906.906,0,0,1-.429-.781q-.005-2.482,0-4.964a.95.95,0,0,1,.953-.946q2.474-.008,4.948,0a.963.963,0,0,1,.957.977.971.971,0,0,1-.962.976c-.779.007-1.558,0-2.338,0h-.2c.062.067.1.113.146.156q2.01,2.01,4.02,4.019a.978.978,0,0,1,.138,1.307.977.977,0,0,1-1.39.2,1.968,1.968,0,0,1-.189-.173q-1.982-1.981-3.962-3.963a1.064,1.064,0,0,1-.108-.155Z" transform="translate(-16.36 -16.358)" fill="#fff"/>
                    <path d="M-118.455,818.906a1.284,1.284,0,0,1-.106.139q-2.009,2.012-4.019,4.022a.977.977,0,0,1-1.228.187.975.975,0,0,1-.25-1.48c.044-.053.095-.1.144-.15l3.953-3.953c.045-.045.1-.084.145-.126l-.014-.042h-.162c-.785,0-1.57.006-2.355,0a.921.921,0,0,1-.866-.558.929.929,0,0,1,.114-1.024.9.9,0,0,1,.731-.37c1.66,0,3.321-.006,4.982,0a.938.938,0,0,1,.934.929q.01,2.491,0,4.982a.961.961,0,0,1-.972.946.971.971,0,0,1-.98-.958c-.007-.78,0-1.559,0-2.339v-.173Z" transform="translate(-49.046 -16.36)" fill="#fff"/>
                    <path d="M-165.359,865.884h.172c.774,0,1.548,0,2.323,0a.976.976,0,0,1,.972.866.976.976,0,0,1-.766,1.067,1.261,1.261,0,0,1-.27.023q-2.395,0-4.789,0a.97.97,0,0,1-1.032-1.033q0-2.435,0-4.869a.968.968,0,0,1,.833-.951.975.975,0,0,1,1.072.671,1.283,1.283,0,0,1,.049.362c0,.758,0,1.516,0,2.274v.189c.067-.062.113-.1.155-.145q2-2,4.009-4.01a.975.975,0,0,1,1.02-.293.977.977,0,0,1,.487,1.551,2.173,2.173,0,0,1-.164.176q-1.982,1.982-3.964,3.963a1.55,1.55,0,0,1-.138.11Z" transform="translate(-16.359 -49.043)" fill="#fff"/>
                    <path d="M-119.812,865.837a1.368,1.368,0,0,1-.152-.114q-2.006-2-4.01-4.009a.975.975,0,0,1-.058-1.405.974.974,0,0,1,1.346-.074c.061.052.117.109.174.166q1.971,1.97,3.941,3.942c.041.041.075.09.113.136l.045-.015V864.3c0-.785,0-1.57,0-2.354a.974.974,0,0,1,.707-.927.958.958,0,0,1,1.1.422,1.034,1.034,0,0,1,.145.481q.016,2.491,0,4.981a.947.947,0,0,1-.945.937q-2.483.009-4.965,0a.962.962,0,0,1-.947-.972.97.97,0,0,1,.957-.98c.779-.008,1.559,0,2.339,0h.174Z" transform="translate(-49.04 -49.046)" fill="#fff"/>
                </g>
            </svg>
        </div>
    </div>
    `;

    var closePopup = popupLib.querySelector('.close');
    var body = document.querySelector('body');

    closePopup.addEventListener('click', function(e){
        e.preventDefault();
        popupLib.classList.remove('show');
        body.classList.remove('hiddenScroll');
    })


    let iconsClick = itemSection.querySelector('.icons');

    iconsClick.addEventListener('click', function(e){
        let itemImg = <HTMLElement>itemSection.children[0];
        
        let swiperWrapper = popupLib.querySelector('.swiper-wrapper');
        swiperWrapper.innerHTML = "";

        swiperWrapper.innerHTML += `
            <div class="swiper-slide">
                <div class="row">
                    <div class="col-12 margin-center">
                        <img class="lazyload" data-src="${itemImg.getAttribute('src')}" alt="${itemImg.getAttribute('alt')}"/>
                    </div>
                </div>
            </div>
        `;

        popupLib.classList.add('show');
        body.classList.add('hiddenScroll');
        initPopupSwiper(0);
    })
}


//     <div class="swiper-button-next">
//     <svg xmlns="http://www.w3.org/2000/svg" width="9.078" height="15.328" viewBox="0 0 9.078 15.328">
//         <path d="M349,1337.065l6.25-6.25,6.25,6.25" transform="translate(-1329.401 362.914) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
//     </svg>
// </div>
// <div class="swiper-button-prev">
//     <svg xmlns="http://www.w3.org/2000/svg" width="9.078" height="15.328" viewBox="0 0 9.078 15.328">
//         <path d="M349,1337.065l6.25-6.25,6.25,6.25" transform="translate(-1329.401 362.914) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/>
//     </svg>
// </div>

function itemPopupHandle(){
    let main = document.querySelector('main');
    
    if (!main.querySelector('.popup-simple')){
        main.innerHTML += `
        <div class="popup-simple">
            <div class="close"></div>
            <div class="container">
                <div class="swiper">
                    <div class="swiper-wrapper">
                    </div>
                </div>
            </div>
        </div>
        `;
    }
    
    let popupLib = <HTMLElement>main.querySelector('.popup-simple');

    let imageItems = document.querySelectorAll('.images-item');
    imageItems.forEach(function(itemSection:HTMLElement){
        clickHandlePopup(itemSection, popupLib);
    })

    let introImgs = document.querySelectorAll('.section-intro__img');
    introImgs.forEach(function(itemSection:HTMLElement){
        if (itemSection.classList.contains('.images-item')){
            return;
        }
        clickHandlePopup(itemSection, popupLib);
    })

    let intro2Imgs = document.querySelectorAll('.section-intro2__img');
    intro2Imgs.forEach(function(itemSection:HTMLElement){
        if (itemSection.classList.contains('.images-item')){
            return;
        }
        clickHandlePopup(itemSection, popupLib);
    })

    let postIntros = document.querySelectorAll('.post-intro');
    postIntros.forEach(function(itemSection){
        let img = <HTMLElement>itemSection.querySelector('.image_wrapper');
        if (img.classList.contains('.images-item')){
            return;
        }
        clickHandlePopup(img, popupLib);
    })

    let utilitiesItems = document.querySelectorAll('.utilities-image__wrapper');
    utilitiesItems.forEach(function(itemSection:HTMLElement){
        if (itemSection.classList.contains('.images-item')){
            return;
        }
        clickHandlePopup(itemSection, popupLib);
    })

    let cuisinesItems = document.querySelectorAll('.cuisine-image__wrapper');
    cuisinesItems.forEach(function(itemSection:HTMLElement){
        if (itemSection.classList.contains('.images-item')){
            return;
        }
        clickHandlePopup(itemSection, popupLib);
    })
}

export const popupImg = function(){
    itemPopupHandle();
}