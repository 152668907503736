// declare var gsap : any;
// declare var $ : any;
// declare var Parallax:any;

function menuHandle(){
    let header = document.querySelector('.header');
    if (header){
        let body = document.querySelector('body');
        let menuBlock = <HTMLElement>header.querySelector('.menu');
        if (!menuBlock){
            return;
        }
        let buttonMenu = header.querySelector('.header-right__button');
        let closeMenu = menuBlock.querySelector('.menu-close');
        let timeoutScroll:any;
        let timeoutResize:any;
        
        buttonMenu.addEventListener('click', function(e){
            e.preventDefault();
            menuBlock.classList.add('show');
        })

        closeMenu.addEventListener('click', function(e){
            e.preventDefault();
            menuBlock.classList.remove('show');
            body.classList.remove('hiddenScroll');
        })

        if (window.scrollY > 200){
            header.setAttribute('data-active', 'false');
        }
        else{
            header.setAttribute('data-active', 'true');
        }
        if (window.scrollY > 90){
            header.classList.add('scroll');
            if (window.innerWidth >= 1200){
                menuBlock.classList.add('show');
            }
        }
        else{
            header.classList.remove('scroll');
        }
        document.addEventListener('scroll', function(e){
            clearTimeout(timeoutScroll);
            timeoutScroll = setTimeout(function(){
                if (window.scrollY > 200){
                    header.setAttribute('data-active', 'false');
                }
                else{
                    header.setAttribute('data-active', 'true');
                }
                if (window.scrollY > 90){
                    header.classList.add('scroll');
                    if (window.innerWidth >= 1200){
                        menuBlock.classList.add('show');
                    }
                }
                else{
                    header.classList.remove('scroll');
                }
            }, 100)
        })

        if (window.innerWidth < 1200){
            header.classList.add('mobile');
        }
        else{
            header.classList.remove('mobile');
        }
        let timeoutMenuBlock:any;
        window.addEventListener('resize', function(e){
            clearTimeout(timeoutResize);
            timeoutResize = this.setTimeout(function(){
                if (window.innerWidth < 1200){
                    if (!header.classList.contains('mobile')){
                        clearTimeout(timeoutMenuBlock);
                        menuBlock.style.display = "none";
                        menuBlock.classList.remove('show');
                        timeoutMenuBlock = setTimeout(function(){
                            menuBlock.style.display = "";
                        }, 900)
                    }
                    header.classList.add('mobile');
                }
                else{
                    header.classList.remove('mobile');
                }
            }, 100)
        })

        let iconBtnMenu = document.querySelector('.header-mobile-right .menu-icon');
        if(iconBtnMenu){
            iconBtnMenu.addEventListener('click', function(e){
                menuBlock.classList.add('show');
                body.classList.add('hiddenScroll');
            })
        }
    }
}

function languagesHandle(){
    let langs = document.querySelectorAll('.languages');
    
    langs.forEach(function(item: HTMLElement){
        let select = item.querySelector('.languages-select');
        let opt = <HTMLElement>item.querySelector('.languages-options');
        let optItems = item.querySelectorAll('.languages-options__item');

        let timeoutAnimation:any;
        let itemCheck = false;

        if (select && opt){
            select.addEventListener('click', function(e){
                e.preventDefault();
                if (itemCheck){
                    return;
                }
                itemCheck = true;
                
                if (item.classList.contains('show')){
                    opt.style.animation = "fadeInOptionsReverse 0.6s ease-in-out forwards"
                    clearTimeout(timeoutAnimation);

                    timeoutAnimation = setTimeout(function(){
                        item.classList.remove('show');
                        itemCheck = false;
                    }, 600)
                }
                else{
                    opt.style.animation = "fadeInOptions 0.6s ease-in-out forwards"
                    item.classList.add('show');

                    itemCheck = false;
                }
            })

            optItems.forEach(function(itemOpt){
                itemOpt.addEventListener('click', function(e){
                    // e.preventDefault();

                    let htmlItem = itemOpt.innerHTML;
                    let nameSelect = select.querySelector('.languages-select__name');
                    nameSelect.innerHTML = htmlItem;

                    opt.style.animation = "fadeInOptionsReverse 0.6s ease-in-out forwards";
                    clearTimeout(timeoutAnimation);

                    timeoutAnimation = setTimeout(function(){
                        item.classList.remove('show');
                        itemCheck = false;
                    }, 600)
                })
            })
        }
        clickOutHandle(item);
    })
}

function clickOutHandle(item:HTMLElement){
    if (!item){
        return;
    }
    
    let opt = <HTMLElement>item.querySelector('.languages-options');
    let timeoutAnimation:any;

    document.addEventListener('click', function(e){
        let evt = <HTMLElement>e.target;
        do{
            if (evt == item){
                return;
            }
            evt = <HTMLElement>evt.parentNode;
        } while(evt)

        if (evt != item){
            opt.style.animation = "fadeInOptionsReverse 0.6s ease-in-out forwards";
            timeoutAnimation = setTimeout(function(){
                item.classList.remove('show');
            }, 600)
        }
    })
}

export const header = function(){
    menuHandle();
    languagesHandle();
}
